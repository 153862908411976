var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isLoggedIn)?_c('div',{staticClass:"main"},[_c('NavBar'),_c('v-overlay',{attrs:{"absolute":"","value":_vm.loading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"#137547"}})],1),(_vm.isLoggedIn)?_c('v-data-table',{key:_vm.allCategories.name,staticClass:"categoryTable",attrs:{"headers":_vm.headers,"show-expand":"","single-expand":"","items":_vm.allCategories,"items-per-page":5},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('h5',[_vm._v("Podkategorije")]),_c('div',{staticClass:"subCat"},_vm._l((item.subCategories),function(p){return _c('div',{key:p.id},[_c('v-chip',{staticClass:"subCatItem",attrs:{"close":"","close-icon":"mdi-delete","color":"#137547","label":""},on:{"click:close":function($event){return _vm.deleteSubcategory(p)}}},[_vm._v(_vm._s(p.name))])],1)}),0)])])])]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Sve kategorije")]),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"success","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Dodaj Kategoriju ")])]}}],null,false,2447351166),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-text-field',{attrs:{"label":"Ime","outlined":""},model:{value:(_vm.editedItem.name),callback:function ($$v) {_vm.$set(_vm.editedItem, "name", $$v)},expression:"editedItem.name"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"error","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Odbaci ")]),_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.addCategory}},[_vm._v(" Sačuvaj ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"success","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Dodaj podkategoriju ")])]}}],null,false,940714725),model:{value:(_vm.dialogAdd),callback:function ($$v) {_vm.dialogAdd=$$v},expression:"dialogAdd"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Dodaj podkategoriju")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"12"}},[_c('v-text-field',{attrs:{"label":"Ime","outlined":""},model:{value:(_vm.subCategories.name),callback:function ($$v) {_vm.$set(_vm.subCategories, "name", $$v)},expression:"subCategories.name"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"12"}},[_c('v-autocomplete',{attrs:{"label":"kategorija","outlined":"","width":"200","items":_vm.allCategories,"item-text":"name","item-value":"id"},model:{value:(_vm.subCategories.categoryId),callback:function ($$v) {_vm.$set(_vm.subCategories, "categoryId", $$v)},expression:"subCategories.categoryId"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"error","text":""},on:{"click":function($event){_vm.dialogAdd = false}}},[_vm._v(" Odbaci ")]),_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.addSubCategory}},[_vm._v(" Sačuvaj ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("Da li ste sigurni da želite obrisati?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',[_vm._v("Cancel")]),_c('v-btn',[_vm._v("OK")]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.deleteCategory(item)}}},[_vm._v(" mdi-delete ")])]}}],null,false,1111816362)}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }