<template>
  <div v-if="isLoggedIn" class="main">
    <NavBar />
    <v-overlay absolute :value="loading">
      <v-progress-circular indeterminate color="#137547"></v-progress-circular>
    </v-overlay>
    <v-data-table v-if="isLoggedIn" :headers="headers" show-expand single-expand :items="allCategories" :key="allCategories.name" :items-per-page="5" class="categoryTable">
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <div class="row">
            <div class="col">
              <h5>Podkategorije</h5>
              <div class="subCat">
                <div v-for="p in item.subCategories" :key="p.id">
                  <v-chip class="subCatItem" close close-icon="mdi-delete" color="#137547" label @click:close="deleteSubcategory(p)">{{ p.name }}</v-chip>
                </div>
              </div>
            </div>
          </div>
        </td>
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Sve kategorije</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="success" dark class="mr-2" v-bind="attrs" v-on="on"> Dodaj Kategoriju </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-text-field v-model="editedItem.name" label="Ime" outlined></v-text-field>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" text class="mr-2" @click="dialog = false"> Odbaci </v-btn>
                <v-btn color="success" @click="addCategory"> Sačuvaj </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogAdd" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="success" dark class="mr-2" v-bind="attrs" v-on="on"> Dodaj podkategoriju </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">Dodaj podkategoriju</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="12">
                      <v-text-field v-model="subCategories.name" label="Ime" outlined></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="12">
                      <v-autocomplete
                        v-model="subCategories.categoryId"
                        label="kategorija"
                        outlined
                        width="200"
                        :items="allCategories"
                        item-text="name"
                        item-value="id"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" text class="mr-2" @click="dialogAdd = false"> Odbaci </v-btn>
                <v-btn color="success" @click="addSubCategory"> Sačuvaj </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">Da li ste sigurni da želite obrisati?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn>Cancel</v-btn>
                <v-btn>OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <!-- <template v-slot:item.actions="{ item }"> -->
        <!-- <v-icon small class="mr-2"> mdi-pencil </v-icon> -->
        <v-icon small class="mr-2" @click="deleteCategory(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import axios from "axios";
import NavBar from "../../Components/NavBar.vue";
export default {
  components: { NavBar },
  data() {
    return {
      loading: false,
      dialog: false,
      dialogAdd: false,
      dialogDelete: false,
      singleExpend: true,
      headers: [
        { text: "Ime", align: "start", value: "name" },
        { text: "Uredi", value: "actions", sortable: false },
      ],
      allCategories: [],
      allSubCategories: [],
      editedIndex: -1,
      editedItem: {
        id: "",
        name: "",
      },
      defaultItem: {
        id: "",
        name: "",
      },
      subCategories: {
        id: "",
        name: "",
        categoryId: "",
      },
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Dodaj kategoriju" : "Uredi";
    },
    isLoggedIn() {
      return this.$store.getters.isAuthenticated;
    },
    currentLoggedUser() {
      return this.$store.getters.StateCurrentUser;
    },
  },
  created() {
    this.getCategory();
  },
  methods: {
    getCategory() {
      this.loading = true;
      axios.get("Category").then((response) => {
        this.allCategories = response.data.map((item) => {
          return {
            ...item,
          };
        });
        this.loading = false;
      });
    },
    async addCategory() {
      await axios.post("Category", this.editedItem);
      this.getCategory();
      this.dialog = false;
      this.editedItem = "";
    },
    async addSubCategory() {
      await axios.post("Category/CreateSubCategory", this.subCategories);
      this.getCategory();
      this.dialogAdd = false;
      this.subCategories = "";
    },
    editItem(item) {
      this.editedIndex = this.allCategories.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogEdit = true;
      this.editedItem = "";
    },
    addItem() {
      this.dialogAdd = true;
    },
    async deleteCategory(item) {
      await axios.delete(`Category/${item.id}`).catch((err) => {
        console.log(err);
      });

      this.getCategory();
    },
    async deleteSubcategory(item) {
      await axios.delete(`Category/subCategory/${item.id}`).catch((err) => {
        console.log(err);
      });

      this.getCategory();
    },
  },
};
</script>
<style lang="scss" scoped>
.main {
  margin-bottom: 85px;
}
.categoryTable {
  width: 1440px;
  margin: 2rem auto;
}
.subCat {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 0.1rem;
  margin-top: 0.1rem;
  .subCatItem {
    margin: 0 0.5rem 0.5rem 0;
    color: #fff;
  }
}
.col {
  margin-top: 0.7rem;
}
</style>
